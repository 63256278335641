import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, shareReplay } from 'rxjs';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { NominationService } from './services/nomination.service';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './app-core/services/notification.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { OnMobileDirective } from './directives/on-mobile.directive';
import { SidebarModule } from 'primeng/sidebar';
import { LeftPanelComponent } from './components/left-panel/left-panel.component';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { BadgeModule } from 'primeng/badge';
import { AsyncPipe } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    MatProgressBar,
    OnMobileDirective,
    SidebarModule,
    LeftPanelComponent,
    MatIconButton,
    MatIcon,
    MatButton,
    MatMenuTrigger,
    BadgeModule,
    RouterLink,
    RouterOutlet,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
    TranslateModule,
  ],
})
export class AppComponent implements OnInit {
  public isAdmin$: Observable<boolean>;
  public areTopNavsAvailable = true;
  public showSidebar = false;
  public nominationCount$: Observable<number>;
  public busy$: Observable<boolean>;
  sub: Subscription
  constructor(
    public readonly authService: OktaAuthStateService,
    public readonly translate: TranslateService,
    private readonly _router: Router,
    private readonly nominationService: NominationService,
    private readonly _authService: AuthService,
    private readonly notificationService: NotificationService,
  ) {
    this.busy$ = this.notificationService.busy$;
    this.isAdmin$ = this._authService.isAdmin();
    this.configureLanguage();
    this.sub = this._router.events.subscribe(c => {
      if (c instanceof NavigationEnd) {
        // nav button on the top should be available only in home view
        this.areTopNavsAvailable = c.url === '/' ? true : false;
        navigator.sendBeacon(`${environment.urls.reportingApi}track`, JSON.stringify(
          {
            hostname: location.hostname,
            pathname: c.url,
          }));
      }
    });
    this.nominationCount$ = this.nominationService.getCount().pipe(
      shareReplay(),
    );
  }


  ngOnInit(): void {
    this.notificationService.checkForUpdates().subscribe(
      sw => console.log(sw.type)
    );
  }

  openUrlClick(url: string) {
    window.open(url, "_blank");
  }


  changeLang(language: string): void {
    localStorage.setItem('language', language);
    this.translate.use(language);
  }


  private async configureLanguage(): Promise<any> {
    this.translate.setDefaultLang('pl-PL');
    await this.translate.use(this.tryToGetUserTranslation()).toPromise();
  }


  private tryToGetUserTranslation(): string {
    let result = 'pl-PL';
    try {
      const storage = localStorage.getItem('language');
      result = storage ? storage : result;
    } catch { }
    return result;
  }

}
