import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../components/shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConfirmDeleteInterceptor implements HttpInterceptor {
    private readonly dialog = inject(MatDialog);
    private readonly translate = inject(TranslateService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.method === 'DELETE') {
            return this.dialog.open(ConfirmDialogComponent, { data: this.translate.instant(`app.confirmDelete`) })
                .afterClosed()
                .pipe(
                    switchMap(confirmed => confirmed ? next.handle(request) : new Observable<HttpEvent<any>>())
                );
        }
        return next.handle(request);
    }
}
