import { Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { RoleGuard } from './guards/role.guard';
import { PermissionType } from './models/permission-type-enum';

const title = 'Portal Pracownika';
export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/main/main.component'),
    title: `${title} | Start`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'vacation',
    loadComponent: () => import('./components/vacation/vacation.component'),
    title: `${title} | Urlopy`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'absence',
    loadComponent: () => import('./components/absence/absence.component'),
    title: `${title} | Absencja`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'rcp',
    loadComponent: () => import('./components/rcp/rcp.component'),
    title: `${title} | Kalendarz Pracy`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'loan',
    loadComponent: () => import('./components/loan/loan.component'),
    title: `${title} | Pożyczki`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'equipment',
    loadComponent: () => import('./components/equipment/equipment.component'),
    title: `${title} | Wyposażenie`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'idea',
    loadComponent: () => import('./components/idea/idea.component'),
    title: `${title} | Idea`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'personal-info',
    loadComponent: () => import('./components/my-info/my-info.component'),
    title: `${title} | Informacje`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'add-nomination',
    loadComponent: () => import('./components/nomination/add-nomination/add-nomination.component'),
    title: `${title} | Nominuj`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'nominations-inbox',
    loadComponent: () => import('./components/nomination/my-nomination/my-nomination.component'),
    title: `${title} | Moje nominacje`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'more/:file',
    loadComponent: () => import('./components/info-carousel/more/more.component'),
    title: `${title} | Więcej`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'salary',
    loadComponent: () => import('./components/salary/salary.component'),
    title: `${title} | Zarobki`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'salary2',
    loadComponent: () => import('./components/future-preview/salary/salary.component'),
    title: `${title} | Zarobki`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'ta',
    loadComponent: () => import('./components/future-preview/ta/ta.component'),
    title: `${title} | RCP`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'absence2',
    loadComponent: () => import('./components/future-preview/absence/absence.component'),
    title: `${title} | Absencja`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'write-to-supervisor',
    loadComponent: () => import('./components/contact/contact.component'),
    title: `${title} | Kontakt`,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'assessment',
    loadComponent: () => import('./components/assessment/assessment.component'),
    title: `${title} | Oceny`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'help',
    loadComponent: () => import('./app-core/ui/sidenav/help/help.component'),
    title: `${title} | Pomoc`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'help/:item',
    loadComponent: () => import('./app-core/ui/sidenav/help/help.component'),
    title: `${title} | Pomoc`,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./components/settings/routes').then(m => m.settingsRoutes),
    title: `${title} | Ustawienia`,
    canActivate: [OktaAuthGuard, RoleGuard],
    data: {
      expectedRole: [PermissionType.Admin]
    },
  },
  { path: 'login/callback', component: OktaCallbackComponent },
];

