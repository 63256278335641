import { ErrorHandler, inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, first, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../services/auth.service";
import { serializeError } from "serialize-error";
import { CustomHeader } from "../models/header.enum";


@Injectable()
export class ErrorService implements ErrorHandler {
    private readonly http = inject(HttpClient);
    private readonly authService = inject(AuthService);


    handleError(error: unknown) {
        console.log('<==================== Error from global error handler ====================>');
        console.error(error);
        console.log('<=============================== End =====================================>');
        if (environment.name !== 'local') {
            this.logException(error).subscribe(
                resp => console.log('Error reported successfully', resp),
            );
        }
    }


    logException(error: unknown): Observable<void> {
        return this.authService.getEmail().pipe(
            first(),
            switchMap(usr => {
                const ex = [{
                    error: serializeError(error),
                    user: usr,
                    localtime: new Date().toLocaleTimeString('pl-PL'),
                    env: environment.name,
                    location: window.location,
                    url: window.location.href,
                    user_agent: window.navigator.userAgent,
                }];
                return this.http.post<void>(`${environment.urls.reportingApi}default`, ex, { headers: { [CustomHeader.SkipNotificationHeader]: '' } })
            })
        )
    }

}